@import "node_modules/angular-notifier/styles";
@import "node_modules/prismjs/themes/prism-coy";

body,
html {
  height: 100%;
}

body {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app-loader {
  width: 240px;
  height: 48px;
  overflow: hidden;
  position: relative;
}

.app-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.app-loading-text {
  top: calc(50% + 48px);
}

.loader-icon {
  position: absolute;
  width: 48px;
  height: 48px;
  left: -60px;
  opacity: 0;
  animation: slide 3s infinite ease-in-out;
  animation-fill-mode: both;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.loader-icon:nth-child(1) {
  animation-delay: 0s;
}

.loader-icon:nth-child(2) {
  animation-delay: 0.5s;
}

.loader-icon:nth-child(3) {
  animation-delay: 1s;
}

.loader-icon:nth-child(4) {
  animation-delay: 1.5s;
}

.loader-icon:nth-child(5) {
  animation-delay: 2s;
}

@keyframes slide {
  0% {
    left: -60px;
    opacity: 0;
    transform: translateX(0);
  }

  50% {
    left: 50%;
    opacity: 1;
    transform: translateX(-50%);
  }

  100% {
    left: 120%;
    opacity: 0;
    transform: translateX(0);
  }
}

.icon1 {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAMAAADVRocKAAAAhFBMVEUAAAA0mNs0mNszl9ozl9o0mNs0mNs0mNs0mNsxktM0mNszltgyk9U0l9ozltg0mNozl9kzl9kyk9U0l9o0l9o0l9ozltgzltg0mNo0l9o0l9ozl9ozltgzltkyldY0l9ozltk0l9o0l9ozltgzltg0l9ozl9kzltgzltk0l9ozltg0mNthfk+WAAAAK3RSTlMAR3oUBfHC/PgLjyoYzDbqcWci3rudQzDv0saYYE0Qr4Sikz4etHhbVdqo23xDHQAAAjhJREFUaN7t2NvSmjAUBeCNgIlyFBEQEDyf1vu/X3XazhT9f8tCO9OLfNcha4fsXCRiGIZhdEwa37uzm8nn57bWh4urFe6Udi/tcefIx2QbF8+ibSif4JyWP+cbb6a/XYMCd+NM3paXAFQZe043Nhzdc1WbyHuOGtCVJ1/ZtQooTvKG5gDg+n2R4X15UxnMXwLR6uUGxRpoJ2/MH+zltXAOpAN7vwQOfy8uuSUsZIgNkN7m75UQC+8ELG3pwyugc34DCrie9HMGAkdIC2bdG2AmHE/fimKWG5G9WgErYviaXYLtIqDGF9x4mbEVVQDVSAfo5uHY2V0PGxQCa2J+x306/lN0WQ9fRCiJgBA4cgHSwiX6qAYy6cpGXYl0HQGP6rq9cFbAmTnGemVxYqrvrhhiJL2l/zpgDJf9RWc2wBGObQJMgAkwASbABPx/AVc+wKcCFnyARwVMoRvhZABx54+BXDgzIKPKqYVTQflES2hUwllizg6n7BV33V8DoTBi4CyEnCzotuSC67sS2ieGW0BF3ye2xPAAyhNOwDxw1MBGSBYQTPq2UAE3Edam909yxoMejJp531tXNfBRLXShZv3OzNyXIVaqR4JTAVEuw8wUsHi9034KRKEMZbnAJXvVnxEwz2W4/AKow3cVZiWA1JZ3TLYKUGndPDd/HABwY3lXmOJGl+vTLvHtOz/Z1dtAAdCLvXzArtX4SrRN5EPsuo1UZ3I1X6wm8klOYtWjX47nnS+GYRjGH34A37phxRpvmqcAAAAASUVORK5CYII=");
}

.icon2 {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAMAAADVRocKAAAAllBMVEUAAAAzl9o0l9ozmNs0mNs0l9owj840mNsyk9Uzl9oylNY0mNszl9kylNYylNY0mNo0l9szltkzltgzltg0l9o0l9ozl9kylNYzltg0l9o0l9ozl9ozl9ozltg0mNs0l9o0mNszl9kzltk0l9ozl9k0l9ozltkyktMylNUzltkyldY0mNszltkzl9k0l9ozl9o0l9k0mNszF8srAAAAMXRSTlMAFO5m4Lgl/KZGH/OVGg3qzFwLB/DWgjQX2tOcilf3yL2wUMJ8qUtALDkR0XFt5Y52tjgUBgAAAwdJREFUaN7t12mTojAQBuBXEVi5PPDCC+9rxpnN//9zS0fL3XLB6YY4VVPl85EKgXQ6nQQvP0s4bGYOIZ6g1gnSurqqp5+rGgyKOou5ujO3O11TPz+oq1wfgxOq6256tx79xtq204Z/e1B/rzyKfXLtKwlmTnz9ptOZNNTF+Igq4oGriB8Mcec48S9zsakSffvS/SY3ZUbTyyeWpcMUjul999eosMFWkbdRyf779HZrhQdmdT0Rpb5weqN32x4ecvp6DBHkdPwXX755Wut5gNiA0z+J9Bc24vx3KT4RK5YUpflRuH4Tml8PLA7NdD+GxEZlVmCaUevfogylJTQB25bGK8nVKRUywb7iUUEcCOp/i9pLc67FXwxW1rxXE1UtimkHXJTZAUSC7BUbTCNaA0OIHGktcAfdof0FQrQDzcAzydp+QigQhLV9t8jYi63N3CcpqT0IOZR5MW/VuLqpUJwlqstbm0PaoyDWZ6fe/i6agpnbs7N0WW4HnD31A+xi0cxaps8MEU3yG8TGNMn8NEWpNPV4+zGV3hBCHn+hYfzkUqGL3fSZxY7ytA+hRFCuaZbVASKUei57l00pRvIIrcF1pjNCV7Tp92gdy84I76Jji/CXPql9DWxhXRhUz78mnSCx/RDCs6PbBNNOfkM4NegmHzLHSyfN5DoDop9aR2CIUhruXr70mYfBaKH0DEt119dLGqt/O4bYSF9QU++L+LfLX5TDRF/Edw+nqkVt+mGVq777YElvXP3/IUoaLRSxUOBdkcUIpcVT+sW5g1zHOQ1wGqOKFXViF5+E/B0qslTRacFxdYmubFw0C2e9zVc3KCqsE9ldV35UXeptvrpV0Rmjzzg+sc8Y26FzZ7hV+kpjwFIVWsIA2k8KtDwYcWioXI0DDInOduM/9jnCy7fxgl85Ag+mtFWuFKb0VK4eTAlUrgDGNK0cTbz8FdVydGGM5ascvgVTWgXVGqZ8FJRrmLJL6jmSV55+t9rEvpnUYN5W/WMLEfmm0IZ5lqtuXAtPcGjeHPDyg/wBNR+mnt+E9BMAAAAASUVORK5CYII=");
}

.icon3 {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAMAAADVRocKAAAAdVBMVEUAAAA0mNs0mNs0mNs0mNs0mNs0mNszmNs0mNs0mNs0mNs0mNs0mNs0mNs0mNs0mNs0mNszldc0l9o0l9sylNUzl9o0mNs0l9ozmNo0l9o0mNozl9kzl9oylNUyk9Qzl9kzl9kzltg0mNo0l9o0l9kxkdE0mNv/vAokAAAAJnRSTlMABfD6D4V6XOujFNdwuI9HKTWd4x8I887Eo4R1QBYNlVRN96aNG5aIwBcAAAFESURBVGje7djJboMwFEBRAyGmJU3LPAQy5/7/J1ZZUySehJW6fXdtcWT8WGCj/eKyU5osLD1lRtqxQ1R3FD6/RVgrE26Iu4neP0BqH++LetgUQHIOOZCOZnHjU8gFwHP92Qg6A6lZXBBCNEqAMYIwkAEbCbBZDRgGt0AOJ5dAVUNdudxBA41xCZR5Xv4I+DNFCrwG2H3MtFsHsMxmVwFiZov9AfLPSfmqwNZM2iqggAIKKKCAAgoooMCfBPZfk/Z+/R/4DyTppMSvKVLg9UC7n9T68x30zNavAmRXZrpmKwBPYTdT5suNlwIiIAjcAueiuLgEggKKwCWQQOISMDYMrdtDripvxpRSApSIANMABwlwABrB+h4IB7O4IQR6yY5rIOrswroIqEvZlsUdjKgYYbERZgsEFdaIqy73t4XdL5XR/nHfRf+/Rzp/sqMAAAAASUVORK5CYII=");
}

.icon4 {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAMAAADVRocKAAAAjVBMVEUAAAA0mNs0mNs0mNs0mNs0mNs0mNs0mNs0mNs0mNs0mNszl9ozl9ozl9k0mNszltg0mNs0l9ozltkzltkzldc0l9ozl9kzl9k0mNszltk0mNs0l9o0l9ozl9ozl9ozltkzltgzltgzl9o0l9o0l9ozl9kzltkzltkylNYxk9Q0l9o0l9ozl9ozl9o0mNsHpl0EAAAALnRSTlMA7AWN+ylwE1aS6hB2NPMI98FbLwzJiINrIOTfsqxmShhDOtjTonpRLBzkvZ1hba6UoAAAAlhJREFUaN7t19ly4jAQBdC2IxPwirENXlgDYQvc//+8oWrIdAsyGRVoHlKl86ZK0HVLLYHJcRzHcRzHtv28Z8l8T/eCHBblAd0oQgDKEgBhQRq/Ak7JqyXJCah8klbAgCwaACuSJlANWdQoTG4SQ7IqxMAFuAAX8GXAOb5aURPHS/sBG1ydaAEk9gOq4dWAFsPhfwgoo6uM/Cj6iZv8swPSF6vSuwDb7gM8q+4Dwsy3KPtmk/2n/LuLCu8pxV8DPPotwVOS6zJ4NwExkPwJmL08aCZniUnaK+Tl558W9Bi+gsscak+ad6Db9i9iYNx/0BiI+xfbDngnXZTDqjyiG9kl1ppum9EXmsCShpxn7eodsXP9JnqhrgMeHep6yaOirjMykR0x1V5WxtpZGmkXQCHPUlqavmD16FP5IV+3lmsMG35kD13GdSt8kIkRsOEnGUOWM4Uop1zLchoPhj+lNgh5maMUm4gfGZj6/CQyjcac9r1oKD/2pn1srq35QFvzD2zIyKvCRAvoawGBFiC6JkdFZtY4LjkuRcWzJNotedYu/Rfje76GnHOgzZnLOaMOiucMUqQBmfBnwJxnqYA3bpUjUi5vlWLt8/opTMlIFMr/DLQ92Wl7MgH2PJqZHjTKkRPTvr8PsjpayOoohnolE8sUM62Ptlof7WQfqUA2qpeRgaySy3DooApuoxRhxCdNodLW651MTCALmAETzt5AjbhSD2nClSq0SzLgH7EuuYCh3PCVdjn0tFN+gkrIRNS1PR4lbbvi0ahtD6LUdu2LUtspOY7jOI7zqF+snZPC+vV7aAAAAABJRU5ErkJggg==");
}

.icon5 {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAMAAADVRocKAAAAjVBMVEUAAAA0mNs0mNs0mNs0mNszldc0mNszl9ozl9o0mNszldY0mNs0mNs0l9oylNYylNYxktMzldc0l9o0mNs0l9ozltkzltgzltkylNYylNY0mNs0l9o0l9s0l9ozl9k0l9o0l9o0l9ozl9kzl9ozl9oylNUzltgzldc0l9syk9Qzltgyldc0l9ozldc0mNuLCRPqAAAALnRSTlMACvXWraz6Uilw7lyFpBIORC/C6+R7PmEgBvDe0LyOsp2VgXRoTCUcyVpHNpgW0isiQQAAAi9JREFUaN7t1+mOokAUhuEDaoHTDcgqi7h3u3/3f3lDocYQLChUkpmknr8qLxQ5VZEURVEURVGU/9blZLjUG3s8YwD7op58+SiNqB9noNfAEgXmsb4CLgq5S3/6CkwAWES9BJyfQlgExh0CWju6Sfbg5AOOsfIyvV1gucSZ6BRwlylksXHnwM5i6MK4BRbbuVTA8FFKZ4thqwBAllwD3zSRCYTg/HmkkQTNAxB1CaxR0DcOSTIAHDoENih4MUmbdgtMGJ9FjaT9DgCcpANOdp31Ku08EOI3NCPpwJp/XauvQiP9dAvs56OWgJ0CzKVuAX8iP2j8Da+oRlsNHlIUssFdHtrXdyEVmAHMpGZ2DmBKNSOJwI4BObUxnz8m7eI4PjQH+MxsqE0M4Nxw4OSOMLAup75FsuR3KZoKBmBgJIKABTCbnhgPH/hg6bvmjSwQHPqjIuAIlq5iTUJbBk4Y0LVnP0LVihqc9ihZHZbIzvU7xhfoQo2OVsCQRlTHzyOz+fQ3vfIVt3FMmwRLMaVmJuNP/yIX7T92dGBIrwqA1KZGYwBzetVcsMDH77sREwyjHJcBfv0RIlQs6HWjp29hXLn+3qbX/eoAtrXtLcMD29E7NvwSh9oGZ14dZwBCesuCF0Jq2C0X9BbHQ8Ez6Tm/+Iw+UWBWlFBd9Il/d84SpWC4nn5VhX65mbzNCCA20Oh9WihM+C59RGIsM4YaNozpc2z3x6g6XkhRFEVRFEX5d/0FJHmQ+rk3L+AAAAAASUVORK5CYII=");
}

a {
  cursor: pointer;
}
